<template>
	<div class="page">
		<div class="option-box">
			<div class="left">
				<Form :model="formItem" inline>
					<FormItem label="发布时间">
						<DatePicker v-model="formItem.publishTimeStart" type="date" placeholder="开始时间"
							format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'publishTime', 0)"
							style="width: 120px"></DatePicker>
						<span class="split">至</span>
						<DatePicker v-model="formItem.publishTimeEnd" type="date" placeholder="截止时间" format="yyyy-MM-dd"
							@on-change="changeRangeVal($event, 'publishTime', 1)" style="width: 120px"></DatePicker>
					</FormItem>
					<!-- <FormItem label="成果类型">
                        <Select v-model="formItem.resultType" clearable filterable>
                            <Option v-for="(item,index) in optionList.resultType" :key="index" :value="item.id">{{item.name}}</Option>
                        </Select>
                    </FormItem> -->
				</Form>
				<Form :model="formItem" inline>
					<FormItem label="内容类型">
						<Select v-model="formItem.detailsType" clearable filterable multiple :max-tag-count="2"
							style="width: 275px">
							<Option v-for="(item, index) in optionList.detailsType" :key="index" :value="item.name">
								{{ item.name }}</Option>
						</Select>
					</FormItem>
					<FormItem label="搜索内容">
						<Input v-model="formItem.title" style="width: 202px" />
					</FormItem>
				</Form>
			</div>

			<div class="right">
				<div class="btn searchBtnLog" data-id="1" @click="search">查询</div>
			</div>
		</div>

		<div class="report-box normal">
			<div class="title">住房市场监测与预警报告</div>
			<div class="list">
				<div class="report-item" v-for="(item, index) in normal" :key="index" @click="gotoDetail(item)">
					<img :src="item.pictureUrl" :alt="item.title" :title="item.overview" />
					<div class="report-title" :title="item.title">{{ item.title }}</div>
					<!-- <div class="cover">
                        <img :src="item.pictureUrl" :alt="item.title">
                    </div> -->
				</div>
			</div>

			<Page :total="normalTotal" :page-size="6" @on-change="getList($event, 1)" />
		</div>



	</div>
</template>

<script>
	import mixin from "@/mixins";
	import formMixin from "@/mixins/form";
	import {
		formatDate
	} from "@/utils";
	import {
		deep_page,
		deep_type
	} from "@/api/reSearch";
	import dataReports from "@/mixins/dataReports";
	export default {
		name: "预警报告",
		mixins: [mixin, formMixin, dataReports],
		data() {
			return {
				formItem: {
					// resultType : 1,
					detailsType: "",
					publishTime: [],
					search: "",
				},
				optionList: {
					resultType: [{
							id: 1,
							name: "常规报告",
						},
						{
							id: 2,
							name: "专题报告",
						},
						{
							id: 3,
							name: "深度研究报告",
						},
					],
					detailsType: [{
							id: 1,
							name: "综合",
						},
						{
							id: 2,
							name: "经济",
						},
						{
							id: 3,
							name: "土地",
						},
						{
							id: 4,
							name: "政策",
						},
					],
				},

				current: 1,
				pageSize: 10,

				normal: [],
				normalTotal: 0,
				pro: [],
				proTotal: 0,
				deep: [],
				deepTotal: 0,
			};
		},
		beforeMount() {
			this.$Notice.destroy();
		},
		beforeDestroy() {
				document.querySelector(".leftChildNav").style.display = "block";
				document.querySelector(".openNav").style.display = "block";

		},
		mounted() {
		//将class 为leftChildNav 的隐藏
				document.querySelector(".leftChildNav").style.display = "none";
				document.querySelector(".openNav").style.display = "none";
			deep_type().then((res) => {
				this.optionList.detailsType = res.map((item, index) => {
					return {
						id: index,
						name: item,
					};
				});
			});
			this.search();
		},
		methods: {
			formatDate(data) {
				return formatDate(data);
			},
			gotoDetail(item) {
				if (!item.perm) {
					// this.$Notice.info({
					//   title: '温馨提示',
					//   desc: `${this.noticeInfo.noAuthority[1003]}`,
					//   render: (h) => {
					//             return h("div", [
					//               `${this.noticeInfo.noAuthority[1003][0]}`,
					//               h(
					//                 "span",
					//                 {
					//                   style: {
					//                     color: "red",
					//                   },
					//                 },
					//                 `${this.noticeInfo.noAuthority[1003][1]}`
					//               ),
					//               `${this.noticeInfo.noAuthority[1003][2]}`,

					//             ]);
					//           },
					//   duration: 5
					// });
					this.$msg.error({
						type: "noAuthority",
						code: 1003
					});

					return;
				}
				this.$router.push({
					path: "/WarnResearch/WarnReportDetail",
					query: {
						id: item.id,
						pageId:this.$route.meta.pageId,
						pageName:this.$route.name
					},
				});
			},
			search() {
				this.getList(1, 4);
				// this.getList(1, 2);
				// this.getList(1, 3);
			},
			getList(page, type) {
				let formItem = this.formItem;
				let params = {
					current: page,
					detailsType: formItem.detailsType,
					publishTime: formItem.publishTime[0] && formItem.publishTime[1] ?
						formItem.publishTime :
						[],
					resultType: type,
					size: type == 1 ? 6 : this.pageSize,
					title: formItem.title,
				};
				deep_page(params).then((data) => {
					switch (type) {
						case 4:
							this.normal = data.records;
							this.normalTotal = data.total;
							// var arr = []
							// this.normal.forEach(item =>{
							//     arr.push(item.overview.replace(/\s+/g,""))
							// })
							// for(let i =0;i<this.normal.length;i++){
							//     this.normal[i].overview = arr[i]
							// }
							break;
						case 2:
							this.pro = data.records;
							// this.pro = []//测试删除

							this.proTotal = data.total;
							break;
						case 3:
							this.deep = data.records;
							// this.deep = []//测试删除
							this.deepTotal = data.total;
							break;
					}
				});
			},
			pageChange(page, type) {},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/style/common.scss";

	.page {
		min-height: 100%;
		min-width: 1200px;
		padding: 20px;
		margin: auto;
		width: 100%;
		overflow: hidden;

		.option-box {
			@include flex;
			justify-content: flex-start;
			margin: 20px 0;
			padding: 0 30px;
			border-radius: 10px;
			border: 1px solid #e3e3e5;

			/deep/ .ivu-form-item {
				margin-right: 60px;

				.ivu-form-item-label {
					width: 70px;
				}

				.ivu-form-item-content {
					display: inline-block;
				}
			}

			.split {
				margin: 0 10px;
			}

			.left {
				padding-top: 24px;
			}

			.right {
				@include flex;

				// margin-left: auto;
				.btn {
					@include flex;
					width: 144px;
					height: 40px;
					margin-right: 20px;
					color: #fff;
					font-size: 18px;
					border-radius: 5px;
					background-color: #00b6ff;
					cursor: pointer;
				}
			}
		}

		.report-box {
			margin-top: 20px;

			.title {
				margin-bottom: 20px;
				font-size: 24px;
				font-weight: bold;
			}

			&.normal {
				.list {
					@include flex;
					justify-content: flex-start;
					padding-top: 20px;
					border-top: 1px solid #d6d7d9;
				}

				.report-item {
					// width: 140px;
					flex: 1;
					max-width: 256px;
					margin-right: 20px;
					// background-color: #eee;
					cursor: pointer;

					img {
						width: 100%;
						// height: 192px;
					}

					.report-title {
						@include ellipsis;
						width: 100%;
						text-align: center;
					}
				}
			}

			&.pro {
				.list {
					padding-top: 20px;
					border-top: 1px solid #d6d7d9;
				}

				.report-item {
					@include flex;
					height: 44px;
					font-size: 16px;
					border-bottom: 1px dotted #d6d7d9;
					cursor: pointer;

					.left {
						@include flex(flex-start);
						flex: 1;

						.item-title {
							// margin-right: auto;
							@include ellipsis;
							// max-width: 50%;
						}
					}

					.mid {
						@include flex;
						flex: 1;

						.type {
							@include flex;
							min-width: 60px;
							height: 24px;
							padding: 0 10px;
							margin: 0 10px;
							// margin-left: auto;
							// margin-right: auto;
							color: #1562d6;
							font-size: 12px;
							border: 1px solid #1562d6;
						}
					}

					.right {
						@include flex(flex-end);
						flex: 1;

						.time {
							// margin-left: 20px;
							color: #999;
							font-size: 14px;
						}
					}
				}
			}

			/deep/ .ivu-page {
				margin-top: 10px;
				text-align: right;
			}
		}
	}
	.no-data{
	text-align: center;
	margin-top: 10px;
	color: #999;

	}
</style>
